<style scoped>
.home {
  width: 100%;
  text-align: center;
  height: 100vh;
  background: url("../assets/mainBg.jpg");
  background-size: 100% 100%;
}
.leitai-back img {
  position: absolute;
  top: 28px;
  left: 20px;
  width: 16pt;
  height: 16pt;
  z-index: 999;
  /* margin: 28px 0 0 20px; */
}
.leitai-title {
  text-align: center;
  font-size: 23px;
  width: 100%;
  height: 10vh;
  line-height: 10vh;
  color: #fff;
  font-weight: 500;
  position: relative;
}
.rankingBody {
  width: 90%;
  height: 85vh;
  margin: 0 5%;
  background: #fff;
  border-radius: 5px;
}
.rankingBody .rankingHeader {
  display: flex;
  height: 50px;
  box-sizing: border-box;
  padding-top: 15px;
  line-height: 35px;
  letter-spacing: 2px;
  position: relative;
  margin-bottom: 10px;
  text-align: center;
}

.rankingBody .rankingHeader > div.headerBox {
  /* display: inline-block;
  vertical-align: top;
  width: 15%; */
  /* margin-right: 10%; */
  flex: 1;
  position: relative;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}
.rankingBody .rankingHeader > div.month {
  margin-right: 0;
}
.rankingBody .rankingHeader > div.lineActivity {
  width: 10%;
  height: 3px;
  border-radius: 8px;
  background: rgb(253, 70, 73);
  position: absolute;
  bottom: 0;
  transition: 0.2s;
}
.boxshow {
  box-shadow: 0px 0px 10px rgba(208, 35, 36, 0.05);
}
.activity {
  color: rgb(253, 70, 73);
}
.lineBody {
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: scroll;
}
.rankingLine {
  width: 90%;
  display: flex;
  height: 35px;
  line-height: 35px;
  background: #fff;
  box-shadow: 0 0 10px rgba(125, 125, 125, 0.17);
  margin: 5px auto;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;
  position: relative;
}
.contenList div {
  margin-top: 4px;
}
.contenList_dept div {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* text-align: left; */
  text-align: center;
}

.rankingLine > div {
  /* display: inline-block; */
  vertical-align: top;
  flex: 1;

  /* width: 33.3%; */
}
.rankingLine > span {
  display: inline-block;
  padding: 0 3px;
  /* width: 20px; */
  height: 15px;
  border-bottom-right-radius: 6px;
  font-size: 13px;
  line-height: 15px;
  background: rgb(253, 70, 73);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
<template>
  <div class="home" style="">
    <div class="leitai-back">
      <img @click="backMain" src="../assets/back.png" alt="" />
    </div>
    <div class="leitai-title">
      排行榜
      <div
        style="
          position: absolute;
          top: 0;
          right: 10px;
          font-size: 12px;
          color: #dfafaf;
        "
      >
        {{ deptName_3rd }}
      </div>
    </div>
    <div class="rankingBody boxshow">
      <div class="rankingHeader">
        <div
          :class="showStatus == 'day' ? 'headerBox activity' : 'headerBox'"
          @click="changeShowRank('day')"
        >
          日榜
        </div>
        <div
          :class="showStatus == 'week' ? 'headerBox activity' : 'headerBox'"
          @click="changeShowRank('week')"
        >
          周榜
        </div>
        <div
          :class="
            showStatus == 'month'
              ? 'headerBox month activity'
              : 'headerBox month'
          "
          @click="changeShowRank('month')"
        >
          月榜
        </div>
        <div
          :class="
            showStatus == 'postRank'
              ? 'headerBox postRank activity'
              : 'headerBox postRank'
          "
          @click="changeShowRank('postRank')"
        >
          车间榜
        </div>
        <div
          :class="
            showStatus == 'deptRank'
              ? 'headerBox deptRank activity'
              : 'headerBox deptRank'
          "
          @click="changeShowRank('deptRank')"
        >
          单位榜
        </div>

        <div class="lineActivity" :style="`left:${activityNum}%`"></div>
      </div>
      <div class="lineBody">
        <div
          v-if="
            showStatus == 'month' || showStatus == 'week' || showStatus == 'day'
          "
        >
          <div
            class="rankingLine"
            style="background: rgb(253, 70, 73); color: #fff"
          >
            <div>姓名</div>
            <div>得分</div>
            <div>用时(分)</div>
            <div>正确率排名</div>
          </div>
          <div>
            <div
              v-for="(item, index) in lineData"
              :key="index"
              class="rankingLine contenList"
            >
              <div>{{ item.userName }}</div>
              <div>{{ item.score }}</div>
              <div>{{ formatTineMs(item.timeMs) }}</div>
              <div v-if="item.paperScore">
                {{ getCorrectRanking(item.userName) }}
                <!-- {{ getPaperPecent(item.paperScore, item.score) }} -->
              </div>
              <div v-else></div>
              <span>{{ index + 1 }}</span>
            </div>
          </div>
        </div>

        <div v-if="showStatus == 'deptRank'">
          <div
            class="rankingLine"
            style="background: rgb(253, 70, 73); color: #fff"
          >
            <div style="flex: 5">单位名称</div>
            <div style="flex: 2">得分</div>
            <div style="flex: 2">用时(分)</div>
            <div style="flex: 3">正确率排名</div>
          </div>

          <div>
            <div
              v-for="(item, index) in lineData"
              :key="index"
              style="font-size: 12px"
              class="rankingLine contenList_dept"
            >
              <div style="color: rgb(98 45 45); flex: 5">
                {{ item.deptName_3rd }}
              </div>

              <div style="flex: 2">{{ item.score }}</div>
              <div style="flex: 2">{{ formatTineMs(item.timeMs) }}</div>
              <div style="flex: 3" v-if="item.paperScore">
                {{ getCorrectRanking(item.deptName_3rd) }}
                <!-- {{ getPaperPecent(item.paperScore, item.score) }} -->
              </div>
              <span>{{ index + 1 }}</span>
            </div>
          </div>
        </div>

        <div v-if="showStatus == 'postRank'">
          <div
            class="rankingLine"
            style="background: rgb(253, 70, 73); color: #fff"
          >
            <div style="flex: 5">姓名/车间</div>
            <div style="flex: 2">得分</div>
            <div style="flex: 2">用时(分)</div>
            <div style="flex: 3">正确率排名</div>
          </div>

          <div>
            <div
              v-for="(item, index) in lineData"
              :key="index"
              style="font-size: 12px"
              class="rankingLine contenList_dept"
            >
              <div
                style="
                  color: rgb(98 45 45);
                  flex: 5;
                  font-size: 8px;
                  text-align: left;
                "
              >
                {{ item.userName }}/{{ item.post }}
              </div>

              <div style="flex: 2">{{ item.score }}</div>
              <div style="flex: 2">{{ formatTineMs(item.timeMs) }}</div>
              <div style="flex: 3" v-if="item.paperScore">
                {{ getCorrectRanking(item.userName) }}
                <!-- {{ getPaperPecent(item.paperScore, item.score) }} -->
              </div>
              <span>{{ index + 1 }}</span>
              <!-- <p></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { wxConPost } from "../api/httpApi"; // Data request API
import globalData from "../api/globalData"; // Data request API

export default {
  name: "Home",
  data() {
    return {
      showStatus: "day", // 显示排行榜的状态  day-天  week-周 month-月
      tabNum: 5,
      activityNum: 7.5, // 下划线的位置  20 45 70
      lineData: [],
      activity: {},
      deptName_3rd: "",
      correctRanking: [],
    };
  },

  components: {},
  methods: {
    // 转化毫秒
    // formatTineMs(time) {
    //   const scond = parseInt(time / 1000);
    //   const showms = time % 1000;
    //   return `${scond}”${showms}`;
    // },
    formatTineMs: (millis) => {
      const minutes = Math.floor(millis / 60000);
      // const seconds = ((millis % 60000) / 1000).toFixed(0);
      // return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
      return minutes;
    },
    // 转化毫秒
    // formatTineMs(time) {
    //   const scond = parseInt(time / 1000);
    //   const showms = time % 1000;
    //   return `${scond}”${showms}`;
    // },
    // 计算正确率排名
    getCorrectRanking(name) {
      const index = _.findIndex(this.correctRanking, { userName: name }) + 1;
      return index;
    },
    // 计算正确率(试卷总分，得分, 答题次数）  总得分 / 答题次数 / 试卷总分
    getPaperPecent(paperScore, score, count) {
      const scoreRate =
        score / paperScore > 1
          ? ""
          : `${parseFloat((score / paperScore) * 100).toFixed(2)}%`;
      return scoreRate;
    },
    // 返回至首页
    backMain() {
      this.$router.replace("/activity-main");
    },
    // 切换显示排行榜
    changeShowRank(str) {
      const onePercent = 100 / this.tabNum;
      const addPercent = (onePercent - 10) / 2;
      if (str == "day") {
        this.activityNum = 0 + addPercent; // 0 + (25-10)/2)
      } else if (str == "week") {
        this.activityNum = onePercent + addPercent; // (25+(25-10)/2)
      } else if (str == "month") {
        this.activityNum = onePercent * 2 + addPercent; //(50+(25-10)/2)
      } else if (str == "postRank") {
        this.activityNum = onePercent * 3 + addPercent; //(75 +(25-10)/2)
      } else if (str == "deptRank") {
        this.activityNum = onePercent * 4 + addPercent; //(75 +(25-10)/2)
      }
      this.showStatus = str;
      this.initData();
    },
    goDetail(page) {
      this.$router.push(`/${page}`);
    },
    working() {
      alert("功能正在开发！！");
    },
    async initData() {
      let _query = {
        id: this.activity.id,
        type: this.showStatus,
        limit: 100,
      };
      let result = [];
      // deptRank 特殊处理
      if (this.showStatus == "deptRank") {
        result = await wxConPost(
          "/services/gttt-answer-exam/rank/getDeptMonthRank",
          {
            id: this.activity.id,
            limit: 200,
          },
        );
      } else if (this.showStatus == "postRank") {
        result = await wxConPost("/services/gttt-answer-exam/rank/getRank", {
          id: this.activity.id,
          limit: 200,
          type: "month",
          bDept: true,
        });
      } else {
        result = await wxConPost(
          "/services/gttt-answer-exam/rank/getRank",
          _query,
        );
      }

      if (result.ret) {
        // 计算正确率排名
        const correctRanking = [];
        for (const _doc of result.ret) {
          if (_doc.paperScore) {
            const score = _doc.score;
            const paperScore = _doc.paperScore;
            const scoreRate =
              score / paperScore > 1
                ? 0
                : parseFloat((score / paperScore) * 100).toFixed(2);
            correctRanking.push({
              userName:
                this.showStatus == "deptRank"
                  ? _doc.deptName_3rd
                  : _doc.userName,
              scoreRate,
            });
          } else {
            correctRanking.push({
              userName:
                this.showStatus == "deptRank"
                  ? _doc.deptName_3rd
                  : _doc.userName,
              scoreRate: 0,
            });
          }
        }
        this.correctRanking = _.sortBy(correctRanking, (item) => {
          return -item.scoreRate;
        });
        this.lineData = result.ret;
        // this.deptName_3rd = result.deptName_3rd ? result.deptName_3rd : "";
      }
    },
  },
  async created() {
    const addPercent = (100 / this.tabNum - 10) / 2;
    this.activityNum = addPercent; // 0 + (25-10)/2)
    this.activity = globalData.getActivity();
    if (!_.isEmpty(this.activity)) {
      this.initData();
    } else {
      this.backMain();
    }
  },
};
</script>
<style></style>
