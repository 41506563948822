var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"leitai-back"},[_c('img',{attrs:{"src":require("../assets/back.png"),"alt":""},on:{"click":_vm.backMain}})]),_c('div',{staticClass:"leitai-title"},[_vm._v(" 排行榜 "),_c('div',{staticStyle:{"position":"absolute","top":"0","right":"10px","font-size":"12px","color":"#dfafaf"}},[_vm._v(" "+_vm._s(_vm.deptName_3rd)+" ")])]),_c('div',{staticClass:"rankingBody boxshow"},[_c('div',{staticClass:"rankingHeader"},[_c('div',{class:_vm.showStatus == 'day' ? 'headerBox activity' : 'headerBox',on:{"click":function($event){return _vm.changeShowRank('day')}}},[_vm._v(" 日榜 ")]),_c('div',{class:_vm.showStatus == 'week' ? 'headerBox activity' : 'headerBox',on:{"click":function($event){return _vm.changeShowRank('week')}}},[_vm._v(" 周榜 ")]),_c('div',{class:_vm.showStatus == 'month'
            ? 'headerBox month activity'
            : 'headerBox month',on:{"click":function($event){return _vm.changeShowRank('month')}}},[_vm._v(" 月榜 ")]),_c('div',{class:_vm.showStatus == 'postRank'
            ? 'headerBox postRank activity'
            : 'headerBox postRank',on:{"click":function($event){return _vm.changeShowRank('postRank')}}},[_vm._v(" 车间榜 ")]),_c('div',{class:_vm.showStatus == 'deptRank'
            ? 'headerBox deptRank activity'
            : 'headerBox deptRank',on:{"click":function($event){return _vm.changeShowRank('deptRank')}}},[_vm._v(" 单位榜 ")]),_c('div',{staticClass:"lineActivity",style:(`left:${_vm.activityNum}%`)})]),_c('div',{staticClass:"lineBody"},[(
          _vm.showStatus == 'month' || _vm.showStatus == 'week' || _vm.showStatus == 'day'
        )?_c('div',[_vm._m(0),_c('div',_vm._l((_vm.lineData),function(item,index){return _c('div',{key:index,staticClass:"rankingLine contenList"},[_c('div',[_vm._v(_vm._s(item.userName))]),_c('div',[_vm._v(_vm._s(item.score))]),_c('div',[_vm._v(_vm._s(_vm.formatTineMs(item.timeMs)))]),(item.paperScore)?_c('div',[_vm._v(" "+_vm._s(_vm.getCorrectRanking(item.userName))+" ")]):_c('div'),_c('span',[_vm._v(_vm._s(index + 1))])])}),0)]):_vm._e(),(_vm.showStatus == 'deptRank')?_c('div',[_vm._m(1),_c('div',_vm._l((_vm.lineData),function(item,index){return _c('div',{key:index,staticClass:"rankingLine contenList_dept",staticStyle:{"font-size":"12px"}},[_c('div',{staticStyle:{"color":"rgb(98 45 45)","flex":"5"}},[_vm._v(" "+_vm._s(item.deptName_3rd)+" ")]),_c('div',{staticStyle:{"flex":"2"}},[_vm._v(_vm._s(item.score))]),_c('div',{staticStyle:{"flex":"2"}},[_vm._v(_vm._s(_vm.formatTineMs(item.timeMs)))]),(item.paperScore)?_c('div',{staticStyle:{"flex":"3"}},[_vm._v(" "+_vm._s(_vm.getCorrectRanking(item.deptName_3rd))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(index + 1))])])}),0)]):_vm._e(),(_vm.showStatus == 'postRank')?_c('div',[_vm._m(2),_c('div',_vm._l((_vm.lineData),function(item,index){return _c('div',{key:index,staticClass:"rankingLine contenList_dept",staticStyle:{"font-size":"12px"}},[_c('div',{staticStyle:{"color":"rgb(98 45 45)","flex":"5","font-size":"8px","text-align":"left"}},[_vm._v(" "+_vm._s(item.userName)+"/"+_vm._s(item.post)+" ")]),_c('div',{staticStyle:{"flex":"2"}},[_vm._v(_vm._s(item.score))]),_c('div',{staticStyle:{"flex":"2"}},[_vm._v(_vm._s(_vm.formatTineMs(item.timeMs)))]),(item.paperScore)?_c('div',{staticStyle:{"flex":"3"}},[_vm._v(" "+_vm._s(_vm.getCorrectRanking(item.userName))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(index + 1))])])}),0)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rankingLine",staticStyle:{"background":"rgb(253, 70, 73)","color":"#fff"}},[_c('div',[_vm._v("姓名")]),_c('div',[_vm._v("得分")]),_c('div',[_vm._v("用时(分)")]),_c('div',[_vm._v("正确率排名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rankingLine",staticStyle:{"background":"rgb(253, 70, 73)","color":"#fff"}},[_c('div',{staticStyle:{"flex":"5"}},[_vm._v("单位名称")]),_c('div',{staticStyle:{"flex":"2"}},[_vm._v("得分")]),_c('div',{staticStyle:{"flex":"2"}},[_vm._v("用时(分)")]),_c('div',{staticStyle:{"flex":"3"}},[_vm._v("正确率排名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rankingLine",staticStyle:{"background":"rgb(253, 70, 73)","color":"#fff"}},[_c('div',{staticStyle:{"flex":"5"}},[_vm._v("姓名/车间")]),_c('div',{staticStyle:{"flex":"2"}},[_vm._v("得分")]),_c('div',{staticStyle:{"flex":"2"}},[_vm._v("用时(分)")]),_c('div',{staticStyle:{"flex":"3"}},[_vm._v("正确率排名")])])
}]

export { render, staticRenderFns }